import { JsSearch } from "../types/JsQuery";

export function valueSearch(column: string, value: string): JsSearch {
  if (!value) return {};
  return { [column]: jqh.eq(value) };
}

type ValueType = string | number | boolean;

/**
 *  JSQuery helper
 */
export const jqh = {
  eq(v: ValueType) {
    return `=${v}`;
  },
  in(a: Array<ValueType>) {
    return this.eq(a.join(";"));
  },
  gt(v: ValueType) {
    return `>${v}`;
  },
  gta(v: ValueType) {
    return `>=${v}`;
  },
  lt(v: ValueType) {
    return `<${v}`;
  },
  lta(v: ValueType) {
    return `<=${v}`;
  },
  not(v: ValueType) {
    return `=!${v}`;
  },
  notIn(v: Array<ValueType>) {
    return this.not(v.join(";"));
  },
  btw(v: ValueType, v2: ValueType) {
    return `<>${v};${v2}`;
  },
  like(v: ValueType) {
    return `=%${v}%`;
  },
  isNull() {
    return "=null";
  },
  isNotNull() {
    return "=!null";
  },
};
